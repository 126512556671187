<template>
    <v-dialog
        v-model="dialog"
        persistent
    >
        <template v-slot:activator="{ props: dialogProps }">
            <v-list-item
                v-bind="dialogProps"
                title="Collega un dispositivo"
            >
                <template v-slot:append>
                    <v-icon
                        color="green-darken-3"
                        icon="$connection"
                    />
                </template>
            </v-list-item>
        </template>

        <v-card rounded>
            <v-toolbar
                class="border-b"
                color="green-darken-3"
                elevation="1"
                tile
                title="Collega un dispositivo"
            >
                <template v-slot:append>
                    <v-btn
                        icon="$close"
                        @click="dialog = !dialog"
                    />
                </template>
            </v-toolbar>

            <v-card-text class="pt-6 pb-8">
                <p class="text-body-2 text-center">Chiedi a un fratello del reparto territori il
                    codice numerico.</p>

                <v-expand-transition hide-on-leave>
                    <v-alert
                        v-if="!!error"
                        class="mt-2"
                        density="compact"
                        type="error"
                    >
                        <div class="text-subtitle-2">
                            {{ error.message }}
                        </div>
                    </v-alert>
                </v-expand-transition>

                <v-otp-input
                    v-model.trim="otp"
                    length="5"
                    type="number"
                    autofocus
                />

                <v-btn
                    :disabled="otp.length < 5"
                    :loading="loading"
                    block
                    class="mt-4"
                    color="green-darken-3"
                    height="40"
                    text="Verifica"
                    @click="onConnect"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { ConnectorApi, ConnectorError } from "@/@api/ConnectorApi";
import { HttpCodes } from "@/@stdlib/network";
import { usePublisherStore } from "@/stores";
import { ref, watch } from "vue";

const PublisherStore = usePublisherStore()

const dialog = ref(false)
const error = ref<ConnectorError | null>(null)
const loading = ref(false)
const otp = ref<string>("")

watch(dialog, dialog => {
    if (!dialog) {
        otp.value = ""
    }
})

async function onConnect() {
    loading.value = true

    const response = await ConnectorApi.connect(otp.value)

    if (typeof response === "string") {
        PublisherStore.setIam(response)

        dialog.value = false
    } else {
        if (response.code === HttpCodes[ "Not Found" ]) {
            otp.value = ""
        }

        error.value = response

        setTimeout(() => error.value = null, 5_000)
    }

    loading.value = false
}

</script>

<style lang="scss" scoped>

</style>
