<template>
    <v-container
        :class="{'pa-0': fullscreen}"
        :fluid="props.fluid || fullscreen"
    >
        <slot
            :desktop="desktop"
            :handheld="handheld"
            name="default"
        />

        <v-overlay
            :content-class="{
                'fill-height align-content-center': feedbackCentered,
                'py-10 text-center': true
            }"
            :model-value="enabled"
            class="justify-center"
            contained
            opacity="0.9"
            persistent
            scrim="white"
            scroll-strategy="block"
        >
            <v-progress-circular
                v-if="!icon"
                :color="color"
                indeterminate
                size="150"
                width="5"
            />
            <v-icon
                v-else
                :color="color"
                size="200"
            >
                {{ icon }}
            </v-icon>

            <p
                v-if="message"
                class="subtitle-1 font-weight-bold mt-5"
                style="max-width: 360px"
            >
                {{ message }}
            </p>
        </v-overlay>
    </v-container>
</template>

<script lang="ts" setup>
import { deepClone } from "@/@stdlib/object";
import { DefaultContainerModel } from "@/@stdlib/vuetify";
import { useAppStore } from "@/stores/AppStore";
import { computed, watch } from "vue";

const emit = defineEmits<{
    (e: 'timeout', status: DefaultContainerModel): void
}>()

const props = defineProps({
    feedbackCentered: {
        default: false,
        type: Boolean,
    },
    fluid: Boolean,
    fullscreen: Boolean,
    timeout: {
        default() {
            return 2.5 * 1_000
        },
        type: Number,
    },
})

const AppStore = useAppStore()

const status = defineModel<DefaultContainerModel>()

let interval: NodeJS.Timeout | null = null

const desktop = computed(() => AppStore.desktop)
const handheld = computed(() => AppStore.handheld)

const enabled = computed(() => !!status.value)
const icon = computed(() => {
    if (status.value && (typeof status.value !== "boolean")) {
        return status.value.icon || null
    }

    return null
})
const color = computed(() => {
    if (status.value && (typeof status.value !== "boolean")) {
        return status.value.color || "primary"
    }

    return "primary"
})
const message = computed(() => {
    if (status.value && (typeof status.value !== "boolean")) {
        return status.value.message
    }

    return null
})

watch(status, (statusValue) => {
    if (statusValue && (typeof statusValue !== "boolean")) {
        if (statusValue.error) {
            statusValue.icon = '$alertOutline'
            statusValue.color = 'red'
            statusValue.message = statusValue.error
        } else if (statusValue.info) {
            statusValue.icon = '$alertOutline'
            statusValue.color = 'blue'
            statusValue.message = statusValue.info
        } else if (statusValue.success) {
            statusValue.icon = '$alertOutline'
            statusValue.color = 'green'
            statusValue.message = statusValue.success
        } else if (statusValue.warning) {
            statusValue.icon = '$alertOutline'
            statusValue.color = 'orange'
            statusValue.message = statusValue.warning
        }

        if (statusValue.icon) {
            interval = setTimeout(() => {
                emit('timeout', deepClone(statusValue))

                status.value = false
            }, props.timeout)
        }
    } else if (!statusValue && interval) {
        clearTimeout(interval)
        interval = null
    }
})
</script>

<style lang="scss" scoped>

</style>
