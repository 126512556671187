<template>
    <v-app>
        <v-app-bar
            id="vAppBar"
            class="border-b"
            color="white"
            elevation="1"
            extension-height="51"
            tile
        >
            <template v-slot:prepend>
                <v-btn
                    v-if="PublisherStore.iam"
                    :icon="navigationDrawer ? '$close' : '$menu'"
                    @click="navigationDrawer = !navigationDrawer"
                />
            </template>

            <v-toolbar-title>
                <portal-target name="toolbar-title">
                    {{ title }}
                </portal-target>
            </v-toolbar-title>

            <template v-slot:append>
                <portal-target name="commands">
                    <v-menu
                        v-if="connector"
                        transition="slide-y-transition"
                        width="280"
                    >
                        <template v-slot:activator="{ props: menuProps }">
                            <v-btn
                                icon="$dotsVertical"
                                v-bind="menuProps"
                                variant="plain"
                            />
                        </template>

                        <v-list>
                            <connector/>
                        </v-list>
                    </v-menu>
                    <v-btn
                        v-else-if="!hasAssigments"
                        :loading="loading"
                        icon="$refresh"
                        @click="onLoadAssignments"
                    />
                </portal-target>
            </template>

            <template
                v-if="assignmentExpiring"
                v-slot:extension
            >
                <v-alert
                    :text="expirationText"
                    border="bottom"
                    color="orange-darken-2"
                    density="compact"
                    icon="$calendarClock"
                    style="font-size: 0.90em"
                    tile
                    type="warning"
                />
            </template>
        </v-app-bar>

        <v-navigation-drawer
            v-model="navigationDrawer"
            border="0"
            location="top"
            temporary
        >
            <template v-slot:prepend>
                <portal-target
                    name="assignment-info"
                />
            </template>

            <portal-target
                name="related-assignments"
            />

            <template v-slot:append>
                <div
                    class="text-center grey--text pa-4 grey darken-4 bg-grey-darken-4"
                    style="font-size: xx-small"
                >
                    <div class="font-weight-bold">&copy; <span v-html="copyYear"/> CC BY-NC-ND 4.0 International</div>
                    <div class="text-uppercase font-weight-bold text-grey-darken-2">ID: {{ appUuid }}</div>
                    <div class="font-italic text-grey-darken-2">{{ title }} ver. {{ version }}</div>
                </div>
            </template>
        </v-navigation-drawer>

        <v-main class="background">
            <router-view/>
        </v-main>
    </v-app>

    <v-overlay
        :model-value="!AppStore.appOnline"
        absolute
        class="align-center justify-center"
        opacity="0.8"
        persistent
    >
        <v-icon
            color="white"
            size="200"
        >
            $wifiOff
        </v-icon>
    </v-overlay>
</template>

<script lang="ts" setup>
import { AssignmentsApi } from "@/@api/AssignmentsApi";
import { settings } from "@/libraries/storage";
import { useAppStore, usePublisherStore } from "@/stores";
import Connector from "@/views/@components/Connector.vue";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";

const appUuid = settings.get('system', 'app', 'uuid')
let copyYear = String(new Date().getFullYear())
if (copyYear !== "2024") {
    copyYear = `2024&minus;${copyYear}`
}
const title = import.meta.env.VITE_NAME
const version = import.meta.env.VERSION

const AppStore = useAppStore()
const PublisherStore = usePublisherStore()

const connector = computed(() => {
    if (import.meta.env.DEV) {
        return !PublisherStore.iam
    }

    return (AppStore.standalone && !PublisherStore.iam)
})
const hasAssigments = computed(() => PublisherStore.assignments.length > 0)
const assignmentExpiring = computed(() => PublisherStore.assignment?.expirationDays !== undefined)
const expirationText = computed(() => {
    if (PublisherStore.assignment?.expirationDays) {
        const expirationDays = PublisherStore.assignment.expirationDays

        if (expirationDays > 1) {
            return `Assegnazione in scadenza tra ${expirationDays} giorni`
        } else {
            return "L'assegnazione scade domani"
        }
    }
})
const loading = ref(false)
const navigationDrawer = ref(false)

onMounted(async () => {
    AppStore.setStandalone(window.matchMedia('(display-mode: standalone)').matches ||
        ((window.navigator as any).standalone === true))

    window.addEventListener('online', () => AppStore.setAppOnline(true))
    window.addEventListener('offline', () => AppStore.setAppOnline(false))
})

onBeforeUnmount(() => {
    window.removeEventListener('online', () => AppStore.setAppOnline(true))
    window.removeEventListener('offline', () => AppStore.setAppOnline(false))
})

watch(() => AppStore.appThemeColor, (themeColor: string) => {
    const meta = document.querySelector<HTMLMetaElement>('meta[name=theme-color]')

    if (meta) {
        const fromColor = parseInt(meta.content.replace('#', ''), 16)
        const toColor = parseInt(themeColor.replace('#', ''), 16)

        let r1 = fromColor >> 16
        let g1 = fromColor >> 8 & 0xff
        let b1 = fromColor & 0xff

        const r2 = toColor >> 16
        const g2 = toColor >> 8 & 0xff
        const b2 = toColor & 0xff

        const duration = 10
        const steps = 500 / duration

        const rStep = (r2 - r1) / steps
        const gStep = (g2 - g1) / steps
        const bStep = (b2 - b1) / steps

        let currentStep = 0

        const timer = setInterval(() => {
            if (currentStep >= steps) {
                clearInterval(timer)
            } else {
                r1 += rStep
                g1 += gStep
                b1 += bStep

                meta.content = '#' + ((1 << 24) + (Math.round(r1) << 16) + (Math.round(g1) << 8) + Math.round(b1))
                    .toString(16)
                    .slice(1)

                currentStep++
            }
        }, duration)
    }
})

async function onLoadAssignments() {
    loading.value = true

    PublisherStore.setAssignments(await AssignmentsApi.list())

    loading.value = false
}
</script>

<style scoped lang="scss">
.background {
    background-color: #fff;
    background-image: url("@/assets/images/235861.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50%;
    transition: background-color 250ms ease-in;
}
</style>
